import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'

import { createInertiaApp } from '@inertiajs/svelte'

const pages = import.meta.glob('../**/*.svelte')

createInertiaApp({ 
  resolve: async name => {
    let layoutPath, componentPath
    if (name.startsWith('admin/')) {
      componentPath = `../${name}.svelte`
      layoutPath = "../admin/_layout.svelte"
    }
    else {
      componentPath = `../themes/${__THEME__}/${name}.svelte`
      layoutPath = `../themes/${__THEME__}/_layout.svelte`      
    }
    if (!pages[componentPath]) throw new Error(`Unknown page ${componentPath}`)
    if (!pages[layoutPath]) throw new Error(`Unknown layout ${layoutPath}`)
    const page = await pages[componentPath]()
    const layout = await pages[layoutPath]()
    return Object.assign({
      layout: layout.default
    }, page)
  },
  setup({ el, App, props }) {
    new App({ target: el, props })
  },
})